.card {
    border: 1px solid #ccc;
    margin: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.card-container {
    display: flex;
    flex-wrap: wrap;
}
.card {
    flex: 0 0 calc(33.33% - 20px); /* Adjust the width as needed for larger screens */
    margin: 10px;
    box-sizing: border-box;
}

@media (max-width: 768px) {
    .card {
        flex: 0 0 calc(50% - 20px); /* Show two cards per row on tablets */
    }
}

@media (max-width: 576px) {
    .card {
        flex: 0 0 calc(50% - 20px); /* Full width on smaller screens (e.g., mobile) */
    }
}



.image-container {
    height: 200px; /* Set your desired fixed height for the card */
    overflow: hidden;
}

    .image-container img {
        width: 100%;
        height: 100%;
        object-fit: cover; /* This property will ensure the image fits within the fixed height */
    }

.card-body {
    padding: 10px;
    text-align: center;
}

.delete-button {
    margin-top: 10px;
}
.sticky-column {
    position: sticky;
    bottom: 0; 
    
}
.post{
    position:relative;
}
