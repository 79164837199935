
::selection{
    color: #ffffff;
    background-color: #31285C;
   }
   

   a{
    color: #31285C;
   }
   
   
   
   
   
   .wrapper{
    width: 320px;
    min-height:100px;
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 5px 5px 15px rgba(0,0,0,0.09);
    padding: 40px;
   }
   
   .input-field{
    width: 100%;
    height: 45px;
    border: none;
    padding: 10px;
    background-color: #eeeeee;
    color: gray;
    outline: none;
    font-size: 15px;
    margin-bottom: 20px;
    transition: .5s;
    border-radius: 5px;
   }
   
   input:hover{
   }
   
   
   .heading{
    color: #3B3663;
    margin-bottom: 20px;
   }
   
   .heading p{ 
    color: #AAA8BB;
   }
   
   .heading i{
    font-size: 30px;
    color: #4D61FC;
   } 
   
   label{
    color: #AAA8BB;
    font-weight: 400;
   }

button {
    width: 100%;
    height: 45px;
    border: none;
    color: #FFFFFF;
    background-color: #5bc0de;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 500;
    transition: 0.3s;
}

    button:hover {
        background-color: #44c4eb;
    }
   
   .row{
    min-width: 5px;
    min-height: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 15px;
   }
   
   .custom-checkbox{
    width: 17px;
    height: 17px;
    border-radius: 5px;
    background-color: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center; 
    font-size: 10px;
    margin-right: 5px;
   }
   
   input[type=checkbox]:checked ~ .custom-checkbox{
    background-color: #31285C;
   }
   
   input[type=checkbox]:checked ~ .custom-checkbox::before {
   font-family: "Font Awesome 5 Free";
     content: "\f00c";
     display: inline-block;
     font-weight: 900;
     color: #ffffff;
   }
.error-message{
    color:red;
    font-size:0.7em;
}
.register {
    color: #3B3663;
    text-decoration:none;
    font-weight:bold;
}